import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/ScrollToTop";

import "./styles/main.css";

import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import RedsHotMap from "./pages/RedsHotMap";
import RedsDoneMap from "./pages/RedsDoneMap";
import RedsActMap from "./pages/RedsActMap";
import Red from "./pages/Red";
import About from "./pages/About";



function App() {

 
  
  return (
    
    <div className="App">


     
     <Router>
          <Navbar/>
          <ScrollToTop/>
          <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/reds-hot' element={<RedsHotMap />} />              
              <Route path='/reds-active' element={<RedsActMap />} />
              <Route path='/reds-closed' element={<RedsDoneMap />} />
              <Route path='/reds/:id' element={<Red />} />
              <Route path='/about' element={<About />} />          
          </Routes>

          
          <Footer/>

      </Router>   

      
    </div>
  );
}



export default App;
