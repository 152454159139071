import { NavLink } from "react-router-dom";
import { useParams } from 'react-router-dom';

import {redslist} from './../helpers/RedsList';
import Separator from '../components/Separator';


const Red = () => { 
    const {id} = useParams ();
    const red = redslist[id];

    
    return ( 

        <main className="section">
        <div className="container">
            
            <div className="project-details">

                <h1 className="title-1">{red.title}</h1>

               

                <img src={red.img} alt="" className="project-details__cover"/>

                <div className="project-details__desc">
                    <p>Agenda:</p>                    
                </div> 
                <div className="project-details__text" > <p>{red.descr}</p> </div>
                <Separator/>

                <div className="project-details__desc">
                    <p>Income per sale:</p>                    
                </div> 
                <div className="project-details__text" > <p>{red.income}</p> </div>
                <Separator/>

                <div className="project-details__desc">    
                    <p>Safe address:</p>
                </div>                

                <div className="project-details__combo">
                <div className="project-details__text" > <p>{red.safe}</p> 
                </div>

                <NavLink to={red.checkLink} target="_blank">
                <button className="btn">
                    Check balance
                </button>
                </NavLink>
                
                </div>

                { /* <div className="btn-placeholder" >
                <NavLink to={red.checkLink} target="_blank">
                <button className="btn">
                    Check balance
                </button>
                </NavLink></div> */}
                <Separator/>
                <div className="project-details__desc">
                    {red.embed && <p>Purchase this RED:</p>}
                </div>
                <div>{red.embed}</div>           

            </div>
        </div>
    </main>
     );
}


 
export default Red;