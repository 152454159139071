import { NavLink } from "react-router-dom";

const Header = () => {
    return ( 

        <header className="header">
              <div className="header__wrapper">
                  <h1 className="header__title">
                      <strong>Astery <em>RED</em> Program</strong><br/>
                      <em>R</em>esearch / <em>E</em>xploration / <em>D</em>evelopment
                  </h1>
                  <div className="header__text">
                      <p>Contribute in jewelry pieces creation and get a part of revenue of their sales.</p>
                  </div>

                  <NavLink to='/about' className="btn">I'm REDy!</NavLink>
                  
              </div>
          </header>


     );
}
 
export default Header;