import { NavLink } from "react-router-dom";
import BtnDarkMode from "../btnDarkMode/BtnDarkMode";

const Navbar = () => {

    const activeLink = 'nav-list__link nav-list__link--active'
    const normalLink = 'nav-list__link'


    return ( 

        <nav className="nav">
                    <div className="container">
                        <div className="nav-row">

                        <NavLink to='/' className="logo">
                        <em><strong>Astery</strong></em> Jewelry
                        </NavLink>  

                        <BtnDarkMode />

                            <ul className="nav-list">

                                <NavLink to='/' className={({isActive}) => isActive ? activeLink : normalLink }>
                                    Home
                                </NavLink>

                                <NavLink to='/reds-hot' className={({isActive}) => isActive ? activeLink : normalLink }>
                                    Hot REDs
                                </NavLink>
                                
                                <NavLink to='/reds-active' className={({isActive}) => isActive ? activeLink : normalLink }>
                                    Active REDs
                                </NavLink>

                                <NavLink to='/reds-closed' className={({isActive}) => isActive ? activeLink : normalLink }>
                                    Closed REDs
                                </NavLink>

                                <NavLink to='/about' className={({isActive}) => isActive ? activeLink : normalLink }>
                                    About
                                </NavLink>

                            </ul>
                        </div>
                    </div>
                </nav>

     );
}
 
export default Navbar;