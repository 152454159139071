
import { NavLink } from "react-router-dom";





import Arachunt from './../img/reds/Arachunt.jpg'
import Zorium from './../img/reds/Zorium.jpg'
import Ecliptica from './../img/reds/Ecliptica.jpg'
import Adernanta from './../img/reds/Adernanta.jpg'
import Regalvea from './../img/reds/Regalvea.jpg'
import Opalaxis from './../img/reds/Opalaxis.jpg'
import Neuroclotum from './../img/reds/Neuroclotum.jpg'
import Ribocortex from './../img/reds/Ribocortex.jpg'
import Arachneuro from './../img/reds/Arachneuro.jpg'


import ArachuntEmbed from "./../components/embeds/ArachuntEmbed";
import ZoriumEmbed from "./../components/embeds/ZoriumEmbed";
import EclipticaEmbed from "./../components/embeds/EclipticaEmbed";
import AdernantaEmbed from "./../components/embeds/AdernantaEmbed";
import RegalveaEmbed from "./../components/embeds/RegalveaEmbed";
import OpalaxisEmbed from "./../components/embeds/OpalaxisEmbed";
import NeuroclotumEmbed from "./../components/embeds/NeuroclotumEmbed";
import RibocortexEmbed from "./../components/embeds/RibocortexEmbed";
import ArachneuroEmbed from "./../components/embeds/ArachneuroEmbed";



const redslist = [

   
    {   tokenId: 8,
        name: 'Arachunt',
        title: 'Arachunt RED card', 
        descr: 'This RED card is proof of your contribution to the development of a mobile power shield generator, codenamed "Arachunt", carried out by Astery engineers. 5% of sales of Arachunt physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Arachunt,
        safe: '0x7d0f8A43c7D1dB9A0b25b40B6afAA58f46f59bB6',  
        embed: <ArachuntEmbed />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x7d0f8A43c7D1dB9A0b25b40B6afAA58f46f59bB6',
        status: 'hot',
    },
       
    {   tokenId: 7,
        name: 'Zorium',
        title: 'Zorium RED card', 
        descr: 'This RED card is proof of your contribution to the development of the mental library access device, codenamed "Zorium", carried out by Astery engineers. 5% of sales of Zorium physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Zorium,
        safe: '0xFDb0B37fDb790926aD1B1e5E59CA867a30EEe05b',    
        embed: <ZoriumEmbed />,    
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xFDb0B37fDb790926aD1B1e5E59CA867a30EEe05b',
        status: 'hot',
    },

    {   tokenId: 6,
        name: 'Ecliptica',
        title: 'Ecliptica RED card', 
        descr: 'This RED card is proof of your contribution to the development of a special portal-generating device, codenamed "Ecliptica", carried out by Astery engineers. 5% of sales of Ecliptica physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Ecliptica,
        safe: '0x4924dB1FfaC503Fbb36Ed01de00CD929f65d18E9',   
        embed: <EclipticaEmbed />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x4924dB1FfaC503Fbb36Ed01de00CD929f65d18E9',
        status: 'hot',
    },

    {   tokenId: 5,
        name: 'Adernanta',
        title: 'Adernanta RED card',
        descr: 'This RED card is proof of your contribution to the research into the breeding of muscle symbionts, codenamed "Adernanta", carried out by Asteri scientists. 5% of sales of Adernanta physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Adernanta,
        safe: '0xa057Da78289d0b8ED4DFbA88a572Cd2955b359Ae',      
        link: <NavLink to='reds/:id'></NavLink>,
        embed: <AdernantaEmbed />,  
        checkLink: 'https://polygonscan.com/address/0xa057Da78289d0b8ED4DFbA88a572Cd2955b359Ae',
        status: 'act',    
    },

    {   tokenId: 4,
        name: 'Regalvea',
        title: 'Regalvea RED card', 
        descr: 'This RED card is proof of your contribution to the exploration of a previously unknown extraterrestrial life form, codenamed "Regalvea", discovered by Astery stalkers. 5% of sales of Regalvea physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Regalvea,
        safe: '0xD01C8b2eDC93DaD56263309688A19373c846A904',   
        embed: <RegalveaEmbed />,   
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xD01C8b2eDC93DaD56263309688A19373c846A904',
        status: 'act',
    },

    {   tokenId: 3,
        name: 'Opalaxis',
        title: 'Opalaxis RED card', 
        descr: 'This RED card is proof of your contribution to the development of a special portal-generating device, codenamed "Opalaxis", carried out by Astery engineers. 5% of sales of physical Opalaxis pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Opalaxis,
        safe: '0x57B914D256aAEF888c4F092f9351bf2F1f731bF2',     
        embed: <OpalaxisEmbed />, 
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x57B914D256aAEF888c4F092f9351bf2F1f731bF2',
        status: 'act',
    },

    {   tokenId: 2,
        name: 'Neuroclotum',
        title: 'Neuroclotum RED card', 
        descr: 'This RED card is proof of your contribution to the exploration of a previously unknown extraterrestrial life form, codenamed "Neuroclotum", discovered by Astery stalkers. 5% of sales of Neuroclotum physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC',
        img: Neuroclotum,
        safe: '0xebc803Beb210d26ad8015e9287B2d402Cd3A992c', 
        // embed: <NeuroclotumEmbed />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xebc803Beb210d26ad8015e9287B2d402Cd3A992c',
        status: 'done',
    },      

    {   tokenId: 1,
        name: 'Ribocortex',
        title: 'Ribocortex RED card', 
        descr: 'This RED card is proof of your contribution to the exploration of a previously unknown extraterrestrial life form, codenamed "Ribocortex", discovered by Astery stalkers. 5% of sales of Ribocortex physical pendants on Astery NFT marketplace are distributed proportionally among all 50 owners of this NFT.',
        income: 'From 0.11 $MATIC', 
        img: Ribocortex, 
        safe: '0xD809b96bC061280b4a8bb646fDcF66781D378AC7',     
        // embed: <RibocortexEmbed />, 
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0xD809b96bC061280b4a8bb646fDcF66781D378AC7',
        status: 'done',
    }, 

    {   tokenId: 0,
        name: 'Arachneuro',
        title: 'Arachneuro RED card', 
        descr: 'This RED card is proof of your contribution to the exploration of a previously unknown extraterrestrial life form, codenamed "Arachneuro", discovered by Astery stalkers. 5% of sales of Arachneuro physical pendants on Astery NFT marketplace are distributed proportionally among all 100 owners of this NFT.', 
        income: 'From 0.11 $MATIC',     
        img: Arachneuro,
        safe: '0x1331ce7361418fE9303C37C208CCF49a38da11F2', 
        // embed: <ArachneuroEmbed />,     
        link: <NavLink to='reds/:id'></NavLink>,
        checkLink: 'https://polygonscan.com/address/0x1331ce7361418fE9303C37C208CCF49a38da11F2',
        status: 'done',
    }, 

]

export {redslist}