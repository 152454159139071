import Header from "./../components/header/Header";
import TopRedsHotMap from "./TopRedsHotMap";


const Home = () => {
    return ( 

        <div>

        <Header/>
        <TopRedsHotMap />
                <main className="section">
                
                    <div className="container">
                  

                            

                    </div>
                </main>

            
        </div>

     );
}
 
export default Home;