
import Separator from '../components/Separator';


const Contacts = () => {
    return ( 

        <main className="section">
        <div className="container">
                <h1 className="title-1">About</h1>

                <ul className="content-list">
                    <li className="content-list__item">
                        <h2 className="title-2">Who we are?</h2>
                        <p>We are Astery, a team of star stalkers constantly exploring deep space in search of artifacts and new life forms! And everyone can join us and become a sponsor of our research, exploration and development activity!</p>
                    </li>
                    <Separator/>
                    <li className="content-list__item">
                        <h2 className="title-2">What is Astery RED Program?</h2>
                        <p>Astery RED Program is a 3D model production co-financing program launched by Astery Jewelry, allowing each participant to claim a portion of the profits from all future sales of sponsored jewelry pieces.</p>
                    </li>
                    <Separator/>
                    <li className="content-list__item">
                        <h2 className="title-2">How it works?</h2>
                        <h3 className="title-3">Step 1</h3>
                        <p>Before we start creating a 3D model of the jewelry, we release an Astery RED card NFT of this jewelry piece in an amount of 50 pieces at a price of 1-5 $MATIC (Depends on REDiness phases).</p>
                        <h3 className="title-3">Step 2</h3>
                        <p>You mint the Astery RED card NFT of this jewelry if you think its design is good enough that the finished silver product will be in demand.</p>
                        <h3 className="title-3">Step 3</h3>
                        <p>Every time this jewelry piece is sold on Astery NFT marketplace, 5% of its value is transferred to a special separate safe contract.</p>
                        <h3 className="title-3">Step 4</h3>
                        <p>Once this safe contract reaches 100 $MATIC, the funds are distributed proportionally among all 50 holders of the corresponding Astery RED card NFT.</p>
                    </li>
                    <Separator/>
                    <li className="content-list__item">
                        <h2 className="title-2">REDiness phases.</h2>
                        <h3 className="title-3">Phase 1. Publication.</h3>
                        <p>When the RED card has just been published and the modeling work has not started yet, you can mint the card for the most profitable price of 1 $MATIC.</p>
                        <h3 className="title-3">Phase 2. Modeling.</h3>
                        <p>While the modeling process started you can mint the correspondent RED card for a higher but yet cheap price of 2 $MATIC.</p>
                        <h3 className="title-3">Phase 3. Manufacturing.</h3>
                        <p>When the model is finished and sent to manufacturing we are one more big step closer to starting its sales and in this phase, you still can mint the correspondent RED card but for a higher price of 3 $MATIC.</p>
                        <h3 className="title-3">Phase 4. Listing.</h3>
                        <p>In this phase, we no longer need the investments as hard as before but if there are remains of the correspondent RED card, you can mint them for a price of 5 $MATIC next week after the sponsored item is listed.</p>
                        <br/>
                        <p><strong><em>*</em></strong> These phases and prices are a subject of future reconsideration when we increase our web3 store sales.</p>
                    </li>
                    <Separator/>
                    <li className="content-list__item">
                        <h2 className="title-2">Example of calculating ROI timing.</h2>                        
                        <p>The minimum cost of physical jewelry piece on <a href="https://astery.space/" target="_blank">Astery NFT marketplace</a> is the equivalent of $109 in MATIC. For simplicity, let's use a round number of 100 $MATIC.</p><br/>                        
                        <p>5% of 100 $MATIC is 5 $MATIC. If we divide this amount by 50 RED card NFTs issued for sponsored physical jewelry piece, this means that the owner of one of corresponding RED card receives 0.1 $MATIC from each sale of that piece - i.e. 1 $MATIC with 10 sales.</p><br/>                        
                        <p>Thus, purchasing Astery RED card NFT at the Phase 1 pays off after 10 sales of the physical jewelry piece you sponsored.</p>                        
                    </li>
                    <Separator/>
                    <li className="content-list__item">
                        <h2 className="title-2">FAQ.</h2>                        
                        <p><strong><em>Q: Can I gift or resell my RED card NFT?</em></strong></p>
                        <p><strong>A: </strong>Yes, you can do this at any time, but from the moment of transfer you lose the right to receive sales royalties.</p><br/>   
                        <p><strong><em>Q: Why are RED card NFTs so cheap?</em></strong></p>
                        <p><strong>A: </strong>We have just begun to develop the web3 direction of Astery and increase the community and client base. Since we currently have no sales in web3, Astery RED Program participants will have to wait quite a long time for a return on their investment. When we reach the level of sales of our web2 market, each such NFT will pay for itself and bring profit much faster, which means it can rise in price quite well, especially for popular models.</p><br/>  
                        <p><strong><em>Q: What happens to the remaining RED cards if the mintout of 50 copies is not reached?</em></strong></p>
                        <p><strong>A: </strong>In any case, the model will be completed and put into production. The remaining RED cards will be mined into a special Astery budget wallet and all future dividends from unsold RED cards will also go to that very Astery budget. These tokens will also be used in giveaways as prizes and rewards.</p><br/>                          
                    </li>

                    {/* <li className="content-list__item">
                       <h2 className="title-2">Telegram / WhatsApp</h2>
                        <p><a href="tel:+79051234567">+7 (905) 123-45-67</a></p>
                    </li>*/}
                    {/* <li className="content-list__item">
                        <h2 className="title-2">Email</h2>
                        <p><a href="mailto:webdev@protonmail.com">webdev@protonmail.com</a></p>
                    </li>*/}
                </ul>

        </div>
    </main>

     );
}
 
export default Contacts;