import './style.css'

import { NavLink } from "react-router-dom";



const RedsCard = ({title, img, index, status}) => {
    return ( 

        <NavLink to={`/reds/${index}`}>
        <li className="project">
                    
            <img src={img} alt={title} className="project__img"/>
            <h3 className="project__title">{title}</h3>

            
                        
        </li>
        </NavLink>
     );
}
 
export default RedsCard;