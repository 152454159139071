import globe from "./../../img/icons/globe.svg";
import twitter from "./../../img/icons/twitter.svg";
import discord from "./../../img/icons/discord.svg";
// import instagram from "./../../img/icons/instagram.svg";
// import linkedIn from "./../../img/icons/linkedIn.svg";


const Footer = () => {


    const today = new Date();
const currentYear = today.getFullYear();

    return (  
        <footer className="footer">
              <div className="container">
                  <div className="footer__wrapper">
                      <ul className="social">  
                          <li className="social__item"><a href="https://astery.space/" target="_blank"><img src={globe} alt="Link"/></a></li>  
                          <li className="social__item"><a href="https://twitter.com/AsteryJewelry" target="_blank"><img src={twitter} alt="Link"/></a></li>
                          <li className="social__item"><a href="https://discord.gg/w9tppQBDJC" target="_blank"><img src={discord} alt="Link"/></a></li>
                          
                          { /* <li className="social__item"><a href="#!"><img src={instagram} alt="Link"/></a></li>*/}
                          { /* <li className="social__item"><a href="#!"><img src={linkedIn} alt="Link"/></a></li>*/}
                      </ul>
                      <div className="copyright">

                        
                          <p>© Astery Jewelry {currentYear}</p>
                      </div>
                  </div>
              </div>
          </footer>



    );
}
 
export default Footer;