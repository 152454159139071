import React from 'react';

const separatorStyles = {
  backgroundColor: '#ddd',
};

const Separator = () => <hr style={separatorStyles} />; //Регулировка отступов происходит в reset.css в настройках тега hr

export default Separator;

