
import RedsCard from './../components/reds-card/RedsCard';
import {redslist} from './../helpers/RedsList';


const RedsHotMap = () => {

    const hotReds = redslist.map((red, index) => ({ ...red, originalIndex: index })).filter((red) => red.status === "hot");
    
    return (

        <main className="section">
        <div className="container">
            <h2 className="title-1">Hot REDs</h2>
            <ul className="projects">
                
             {
             hotReds.map((red, index) => { 
             // quests.slice(1).map((quest, index) => { 
             //quests.slice(-2).reverse().map((hotredslist, index) => { 
                    return (
                    <RedsCard 
                        key={red.name} 
                        index={red.originalIndex}
                        title={red.title} 
                        img={red.img} 
                        link={red.link}
                        checkBtn={red.checkBtn}
                        buyLink={red.buyLink}
                    />)
                })}
               

            </ul>
        </div>
    </main>
     );
}                   
                    
                
 
export default RedsHotMap